import * as React from 'react';

type Props = {
  defaultValue: string,
  name: string,
  type: string,
  isRequired: boolean,
};

type State = {
  value: string,
};

class StringField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.defaultValue,
    }
  }

  render () {
    const { name, type, isRequired } = this.props;
    const { value } = this.state;

    return <>
      <input
        type={type}
        className="form-control form-control-lg"
        required={isRequired}
        onChange={e => this.setState({ value: e.target.value })}
      />
      <input type="hidden" name={name} value={value} />
    </>;
  }
}

export default StringField;