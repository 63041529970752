import React, { useState } from 'react';
import UserTagCheckboxes, { UserTagOptions } from './UserTagCheckboxes';

type Props = {
  availabilityOptions: {
    value: string,
    label: string,
  }[],
  userTagOptions: UserTagOptions,
  defaultUserTags: string[],
  defaultAvailability: string,
  availabilitiesWithUserTags: string[],
};

const PaidUserRankAvailabilityFields: React.FC<Props> = ({
  availabilityOptions,
  userTagOptions,
  defaultUserTags,
  defaultAvailability,
  availabilitiesWithUserTags
}) => {
  const [availability, setAvailability] = useState<string>(defaultAvailability);
  const [userTags, setUserTags] = useState<string[]>(defaultUserTags);

  const handleAvailabilityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!availabilitiesWithUserTags.includes(e.target.value)) {
      setUserTags([]);
    }
    setAvailability(e.target.value);
  }

  return(
    <>
      <div className="form-group mb-4">
        <label htmlFor="paid_user_rank_availability">新規受付対象ユーザ</label>
        <select
          className="form-control form-control-lg"
          id="paid_user_rank_availability"
          value={availability}
          onChange={handleAvailabilityChange}
        >
          {availabilityOptions.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      {availabilitiesWithUserTags.includes(availability) && <div className="form-group mt-n1 mb-4">
        <UserTagCheckboxes
          userTagOptions={userTagOptions}
          userTags={userTags}
          setUserTags={setUserTags}
        />
      </div>}

      <input type="hidden" name="paid_user_rank[availability]" value={availability} />
      <input type="hidden" name="paid_user_rank[user_tag_uids][]" value="" />
      {userTags.map((userTag) => (
        <input
          type="hidden"
          name={"paid_user_rank[user_tag_uids][]"}
          value={userTag}
          key={userTag}
        />
      ))}
    </>
  )
}

export default PaidUserRankAvailabilityFields;