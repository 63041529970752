import * as React from 'react';
import axios from 'axios';
import Rails from 'rails-ujs';
import * as classNames from 'classnames';
import * as snakecaseKeys from 'snakecase-keys';

type ScreeningFilterCondition = {
  method: string|null,
  variant: string|null,
  operator: string|null,
  primaryValue: string|null,
  secondaryValue: string|null,
};

type ScreeningMethod = {
  key: string,
  name: string,
  variants: {
    key: string,
    name: string,
  }[],
  operators: {
    key: string,
    label: string,
  }[]
};

type ScreeningFilter = {
  uid: string,
  filterName: string,
  filterConditions: ScreeningFilterCondition[],
};

type ScreeningFilterResponse = {
  conditions: {
    method: string,
    variant: string,
    operator: string,
    primary_value: string,
    secondary_value: string|null,
  }[],
  filter_name: string,
  uid: string,
}

type Props = {
  screeningMethods: ScreeningMethod[],
  defaultFilterName: string,
  defaultFilterConditions: ScreeningFilterCondition[],
  defaultSavedScreeningFilterUid: string|null,
  isPersisted: boolean,
  defaultScreeningFilters: ScreeningFilter[],
  submitPath: string,
};

type State = {
  filterName: string,
  filterConditions: ScreeningFilterCondition[],
  savedScreeningFilters: ScreeningFilter[],
  selectedSavedScreeningFilterUid: string,
  filterNameErrorMessage: string,
  hasFilterNameIsValidStyle: boolean,
};

const INIT_FILTER_CONDITION: ScreeningFilterCondition = {
  method: null,
  variant: null,
  operator: null,
  primaryValue: null,
  secondaryValue: null,
};

class ScreeningFilterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let filterConditions = props.defaultFilterConditions;
    if (filterConditions.length === 0) {
      filterConditions = [INIT_FILTER_CONDITION];
    }

    this.state = {
      filterName: props.defaultFilterName,
      filterConditions: filterConditions,
      savedScreeningFilters: props.defaultScreeningFilters,
      selectedSavedScreeningFilterUid: props.defaultSavedScreeningFilterUid || '',
      filterNameErrorMessage: '',
      hasFilterNameIsValidStyle: false,
    };

    this.setFilterCondition = this.setFilterCondition.bind(this);
    this.appendFilterCondition = this.appendFilterCondition.bind(this);
    this.removeFilterCondition = this.removeFilterCondition.bind(this);
    this.swapFilterConditions = this.swapFilterConditions.bind(this);
    this.saveScreeningFilter = this.saveScreeningFilter.bind(this);
    this.setSavedScreeningFilter = this.setSavedScreeningFilter.bind(this);
    this.removeScreeningFilter = this.removeScreeningFilter.bind(this);
  }

  setFilterCondition (index: number, values: Partial<ScreeningFilterCondition>) {
    const { filterConditions } = this.state;

    let newFilterConditions = [...filterConditions];
    newFilterConditions[index] = {
      ...newFilterConditions[index], ...values
    };
    this.setState({ filterConditions: newFilterConditions });
  }

  appendFilterCondition () {
    const { filterConditions } = this.state;
    const newFilterConditions = [...filterConditions, INIT_FILTER_CONDITION];
    this.setState({ filterConditions: newFilterConditions });
  }

  removeFilterCondition (index: number) {
    const { filterConditions } = this.state;
    let newFilterConditions = [...filterConditions];
    newFilterConditions.splice(index, 1);
    this.setState({ filterConditions: newFilterConditions });
  }

  swapFilterConditions (index1: number, index2: number) {
    const { filterConditions } = this.state;
    let newFilterConditions = [...filterConditions];
    newFilterConditions[index1] = filterConditions[index2];
    newFilterConditions[index2] = filterConditions[index1];
    this.setState({ filterConditions: newFilterConditions });
  }

  async saveScreeningFilter () {
    const {
      filterName,
      filterConditions,
      savedScreeningFilters,
      selectedSavedScreeningFilterUid,
    } = this.state;

    const savedScreeningFilter = savedScreeningFilters.find(
      sf => sf.uid === selectedSavedScreeningFilterUid
    );

    let url, method;
    if (savedScreeningFilter) {
      url = `/user/screening_filters/${savedScreeningFilter.uid}`;
      method = 'patch';
    } else {
      url = `/user/screening_filters`;
      method = 'post';
    }

    try {
      const response = await axios.request({
        url: url,
        method: method,
        data: {
          filter_name: filterName,
          conditions: filterConditions.map(fc => ({
            method: fc.method,
            variant: fc.variant,
            operator: fc.operator,
            primary_value: fc.primaryValue,
            secondary_value: fc.secondaryValue,
          })),
          authenticity_token: Rails.csrfToken(),
        },
      });

      const screeningFilterResponse: ScreeningFilterResponse = response.data;
      const newSavedScreeningFilter: ScreeningFilter = {
        filterName: screeningFilterResponse.filter_name,
        uid: screeningFilterResponse.uid,
        filterConditions: screeningFilterResponse.conditions.map(cond => ({
          method: cond.method,
          variant: cond.variant,
          operator: cond.operator,
          primaryValue: cond.primary_value,
          secondaryValue: cond.secondary_value,
        }))
      }

      this.setSavedScreeningFilter(newSavedScreeningFilter);
    } catch(e) {
      console.log(e);
      this.setState({
        filterNameErrorMessage: '保存に失敗しました。スクリーニング項目をご確認ください。'
      });
    }
  }

  setSavedScreeningFilter (savedScreeningFilter: ScreeningFilter) {
    const { savedScreeningFilters } = this.state;
    let newSavedScreeningFilters: ScreeningFilter[];

    if (savedScreeningFilters.some(sf => sf.uid === savedScreeningFilter.uid)) {
      newSavedScreeningFilters = savedScreeningFilters.map(fc => {
        if (fc.uid === savedScreeningFilter.uid) {
          return savedScreeningFilter;
        } else {
          return fc;
        }
      });
    } else {
      newSavedScreeningFilters = [...savedScreeningFilters, savedScreeningFilter];
    }

    this.setState({
      savedScreeningFilters: newSavedScreeningFilters,
      selectedSavedScreeningFilterUid: savedScreeningFilter.uid,
      filterName: savedScreeningFilter.filterName,
      filterConditions: savedScreeningFilter.filterConditions,
      filterNameErrorMessage: '',
      hasFilterNameIsValidStyle: true,
    }, () => {
      setTimeout(() => this.setState({ hasFilterNameIsValidStyle: false }), 500);
    });
  }

  async removeScreeningFilter (screeningFilterUid: string) {
    const {
      savedScreeningFilters,
    } = this.state;

    try {
      const response = await axios.request({
        url: `/user/screening_filters/${screeningFilterUid}`,
        method: 'delete',
        data: {
          authenticity_token: Rails.csrfToken(),
        },
      });

      const newSavedScreeningFilters = savedScreeningFilters.filter(
        sf => sf.uid !== screeningFilterUid
      );
      this.setState({
        savedScreeningFilters: newSavedScreeningFilters,
        selectedSavedScreeningFilterUid: '',
        filterName: '',
        filterConditions: [INIT_FILTER_CONDITION],
        hasFilterNameIsValidStyle: true,
      }, () => {
        setTimeout(() => this.setState({ hasFilterNameIsValidStyle: false }), 500);
      });
    } catch(e) {
      console.log(e);
      this.setState({
        filterNameErrorMessage: '保存に失敗しました。スクリーニング項目をご確認ください。'
      });
    }
  }

  render () {
    const { screeningMethods, submitPath } = this.props;
    const {
      filterName,
      filterConditions,
      savedScreeningFilters,
      selectedSavedScreeningFilterUid,
      filterNameErrorMessage,
      hasFilterNameIsValidStyle,
    } = this.state;

    const buildConditionTableLine = (filterCondition: ScreeningFilterCondition, index: number) => {
      const screeningMethod = screeningMethods.find(sm => sm.key === filterCondition.method);
      return <tr key={index}>
        <td>
          <select
            value={filterCondition.method || ''}
            onChange={e => this.setFilterCondition(index, {
              ...INIT_FILTER_CONDITION,
              method: e.target.value,
            })}
            className="form-control form-control-sm"
          >
            <option value=''>選択してください</option>
            {screeningMethods.map(sm => <option key={sm.key} value={sm.key}>
              {sm.name}
            </option>)}
          </select>
        </td>
        <td>
          <select
            value={filterCondition.variant || ''}
            onChange={e => this.setFilterCondition(index, { variant: e.target.value })}
            disabled={!screeningMethod}
            className="form-control form-control-sm"
          >
            <option value=''>-----</option>
            {screeningMethod && screeningMethod.variants.map(v => <option key={v.key} value={v.key}>
              {v.name}
            </option>)}
          </select>
        </td>
        <td>
          <select
            value={filterCondition.operator || ''}
            onChange={e => this.setFilterCondition(index, { operator: e.target.value })}
            disabled={!screeningMethod}
            className="form-control form-control-sm"
          >
            <option value=''>-----</option>
            {screeningMethod && screeningMethod.operators.map(o => <option key={o.key} value={o.key}>
              {o.label}
            </option>)}
          </select>
        </td>
        <td>
          <input
            type="text"
            value={filterCondition.primaryValue || ''}
            onChange={e => this.setFilterCondition(index, { primaryValue: e.target.value })}
            disabled={!filterCondition?.operator || ['show', 'true', 'false'].includes(filterCondition?.operator)}
            className="form-control form-control-sm"
          />
        </td>
        <td>
          <input
            type="text"
            value={filterCondition.secondaryValue || ''}
            onChange={e => this.setFilterCondition(index, { secondaryValue: e.target.value })}
            disabled={filterCondition?.operator !== 'between'}
            className="form-control form-control-sm"
          />
        </td>
        <td className="text-end">
          {index > 0 && <button
            onClick={() => this.swapFilterConditions(index, index-1)}
            className="btn btn-sm btn-outline-secondary"
          >
            <i className="fas fa-arrow-up"></i>
          </button>}
          {index < filterConditions.length-1 && <button
            onClick={() => this.swapFilterConditions(index, index+1)}
            className="btn btn-sm btn-outline-secondary ms-1"
          >
            <i className="fas fa-arrow-down"></i>
          </button>}
          <button
            onClick={() => this.removeFilterCondition(index)}
            className="btn btn-sm btn-outline-danger ms-1"
          >
            X
          </button>
        </td>
      </tr>;
    }

    return(
      <div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h1>銘柄スクリーニング</h1>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-end">
            <div className="w-100">
              {savedScreeningFilters.length > 0 && <div className="input-group input-group-sm">
                <select
                  value={selectedSavedScreeningFilterUid || ''}
                  onChange={e => {
                    const selectedSavedScreeningFilter = savedScreeningFilters.find(
                      sf => sf.uid === e.target.value
                    );
                    if (selectedSavedScreeningFilter) {
                      this.setState({
                        selectedSavedScreeningFilterUid: selectedSavedScreeningFilter.uid,
                        filterName: selectedSavedScreeningFilter.filterName,
                        filterConditions: [...selectedSavedScreeningFilter.filterConditions],
                        filterNameErrorMessage: '',
                      });
                    } else {
                      this.setState({
                        selectedSavedScreeningFilterUid: '',
                        filterName: '',
                        filterConditions: [INIT_FILTER_CONDITION],
                        filterNameErrorMessage: '',
                      });
                    }
                  }}
                  className="form-control"
                >
                  <option value="">保存済みの条件を選択</option>
                  {savedScreeningFilters.map(sf => <option value={sf.uid} key={sf.uid}>
                    {sf.filterName}
                  </option>)}
                </select>
              </div>}

              <div className="input-group input-group-sm has-validation">
                <input
                  type="text"
                  value={filterName || ''}
                  onChange={e => this.setState({ filterName: e.target.value })}
                  className={classNames("form-control", {
                    "is-valid": hasFilterNameIsValidStyle,
                    "is-invalid": filterNameErrorMessage !== '',
                  })}
                />
                <button
                  className="btn btn-outline-secondary"
                  onClick={this.saveScreeningFilter}
                >
                  検索条件を保存
                </button>
                <button
                  className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                  data-bs-toggle="dropdown"
                >
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <a className="dropdown-item text-danger" href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeScreeningFilter(selectedSavedScreeningFilterUid);
                      }}
                    >
                      検索条件を削除
                    </a>
                  </li>
                </ul>
                {filterNameErrorMessage !== '' && <div className="invalid-feedback">
                  {filterNameErrorMessage}
                </div>}
              </div>
            </div>
          </div>
        </div>
        <table className="table table-sm">
          <thead>
            <tr>
              <th>項目</th>
              <th>詳細</th>
              <th>演算子</th>
              <th>入力値①</th>
              <th>入力値②</th>
            </tr>
          </thead>
          <tbody>
            {filterConditions.map(buildConditionTableLine)}
          </tbody>
        </table>
        <div className="row">
          <div className="col-12 col-md-3">
            <button
              onClick={this.appendFilterCondition}
              className="btn btn-sm btn-outline-primary"
            >
              条件を追加
            </button>
          </div>
          <div className="col-12 col-md-6 text-center">
            <form action={submitPath} method="post">
              <button className="btn btn-lg btn-primary">スクリーニング実行</button>
              <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
              <input type="hidden" name="filter_conditions" value={JSON.stringify(snakecaseKeys(filterConditions))} />
              <input type="hidden" name="selected_saved_screening_filter_uid" value={selectedSavedScreeningFilterUid} />
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default ScreeningFilterForm;