// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import * as ClipboardJS from 'clipboard';
import "channels";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import iframeResize from '@iframe-resizer/parent';
import * as echarts from 'echarts';
import 'echarts/theme/blue';
import 'src/ai-screening';

window.echarts = echarts;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Clipboard copy button
document.addEventListener("turbolinks:load", function() {
  const clipboard = new ClipboardJS('.btn');
  clipboard.on('success', (e) => {
    const copyButtonDom = e.trigger;
    const tooltip = bootstrap.Tooltip.getOrCreateInstance(copyButtonDom, {
      title: 'コピーしました'
    });
    copyButtonDom.addEventListener('shown.bs.tooltip', (e) => {
      tooltip.disable();
    });
    tooltip.enable();
    tooltip.show();
  });
});

// Generic tooltip
// https://getbootstrap.com/docs/5.0/components/tooltips/#example-enable-tooltips-everywhere
document.addEventListener("turbolinks:load", function() {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });
});

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Iframe resizer
document.addEventListener("turbolinks:load", function() {
  iframeResize({ license: "GPLv3", waitForLoad: true }, '.iframe-resized' );
});

