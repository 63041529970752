import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Rails from 'rails-ujs';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

type Props = {
  modalId: string,
  defaultInstrument: InstrumentOption|null,
  defaultSubscription: Subscription|null,
  subscriptions: Subscription[],
  isSubscriptionReadOnly: false,
  isInTradingHours: boolean,
};

type Subscription = {
  title: string,
  uid: string,
  allocatedJpy: number,
};

type FetchedInstrument = {
  ticker: string,
  title: string,
  trading_lot: number,
};
type InstrumentOption = {
  value: string,
  label: string,
  tradingLot: number,
};

type Quote = {
  currentPrice: string|null,
  currentPriceAt: Date|null,
};

const fetchInstruments = (inputValue: string) => {
  return new Promise<InstrumentOption[]>(async (resolve, reject) => {
    try {
      const response = await axios.request({
        url: "/user/instruments/search",
        method: 'get',
        params: {
          q: inputValue,
        }
      });
      const instruments: FetchedInstrument[] = response.data.instruments;
      const instrumentOptions = instruments.map(instrument => ({
        value: instrument.ticker,
        label: `[${instrument.ticker}] ${instrument.title}`,
        tradingLot: instrument.trading_lot,
      }));

      resolve(instrumentOptions);
    } catch (error) {
      reject(error);
    }
  });
}

const getTimeInJST = (date: Date): string => {
  if (!date) return "";
  return date.toLocaleTimeString('en-US', {
    timeZone: 'Asia/Tokyo',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false  // Use 24-hour format
  });
}

const ManualEntryOrderForm: React.FC<Props> = ({
  modalId,
  defaultInstrument,
  defaultSubscription,
  subscriptions,
  isSubscriptionReadOnly,
  isInTradingHours,
}) => {
  const [subscription, setSubscription] = useState<Subscription|null>(defaultSubscription);
  const [instrument, setInstrument] = useState<InstrumentOption|null>(defaultInstrument);
  const [quantity, setQuantity] = useState<number|null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [side, setSide] = useState<string|null>(null); // ['buy', 'sell']
  const [quantityMethod, setQuantityMethod] = useState<string>('auto'); // ['auto', 'manual']
  const [quote, setQuote] = useState<Quote|null>(null);
  const [hasShown, setHasShown] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  if (!isInTradingHours) {
    return(
      <div className="alert alert-warning mb-0">
        現在取引時間外のため、取引を行うことができません。
      </div>
    );
  }

  const updateQuote = async () => {
    try {
      const response = await axios.request({
        url: "/user/manual_entry_orders/quote",
        method: 'get',
        params: {
          instrument_ticker: instrument?.value,
        }
      });
      const quote: Quote = {
        currentPrice: response.data.current_price,
        currentPriceAt: (
          response.data.current_price_at ? new Date(response.data.current_price_at*1000) : null
        ),
      };
      setQuote(quote);
    } catch (error) {
      setQuote(null);
    }
  };

  const canSubmit = (
    !!instrument && !isSubmitting && !!subscription && !!quote?.currentPrice
  );
  const renderSubmitButton = (value, label, classNames='') => {
    return(
      <button
        type="submit"
        name="side"
        value={value}
        className={classnames("btn", classNames)}
        disabled={!canSubmit}
        onClick={(e)=> {
          e.preventDefault();
          setSide(value);
        }}
      >
        {label}
      </button>
    );
  }

  useEffect(() => {
    if (!side) return;
    setIsSubmitting(true);
    formRef.current.submit();
  }, [side]);

  useEffect(() => {
    if (!instrument) return;
    if (!hasShown) return;
    updateQuote();
  }, [instrument, hasShown]);

  useEffect(() => {
    const modalElement = document.getElementById(modalId);
    if (!modalElement) return;
    modalElement.addEventListener('show.bs.modal', () => {
      setHasShown(true);
    });
  }, [modalId]);

  return(<>
    <label>戦略</label>
    <select className="form-select" disabled={isSubscriptionReadOnly}
      value={subscription?.uid || ''}
      onChange={e => setSubscription(subscriptions.find(s => s.uid === e.target.value) || null)}
    >
      <option value="">選択してください</option>
      {subscriptions.map(subscription => (
        <option key={subscription.uid} value={subscription.uid}>
          {subscription.title}（{(subscription.allocatedJpy/10000)}万円割当済み）
        </option>
      ))}
    </select>
    <label className="mt-1">取引銘柄</label>
    <AsyncSelect cacheOptions
      defaultValue={defaultInstrument}
      loadOptions={fetchInstruments}
      onChange={(newOption: InstrumentOption) => {
        setInstrument(newOption);
        setQuantity(newOption.tradingLot);
        setQuote(null);
      }}
    />
    <label className="mt-1">取引数量</label>
    <div className="manual-order-modal__radio-set d-flex">
      <div className="form-check">
        <label className="form-check-label">
          <input className="form-check-input" type="radio"
            checked={quantityMethod === 'auto'}
            onChange={e => setQuantityMethod(e.target.checked ? 'auto' : 'manual')}
            value='auto'
          />
          投資金額に合わせて自動計算
        </label>
      </div>
      <div className="form-check ms-2">
        <label className="form-check-label">
          <input className="form-check-input" type="radio"
            checked={quantityMethod === 'manual'}
            onChange={e => setQuantityMethod(e.target.checked ? 'manual' : 'auto')}
            value='manual'
          />
          数量を手動指定
        </label>
      </div>
    </div>
    {(instrument && quantityMethod === 'manual') && <>
      <label className="mt-1">手動指定数量（{instrument.tradingLot}単位）</label>
      <input type="number" className="form-control"
        step={instrument.tradingLot}
        min={instrument.tradingLot}
        value={quantity || ''}
        onChange={e => setQuantity(parseInt(e.target.value))}
      />
    </>}
    {quote && <>
      <div className="d-flex justify-content-between align-items-end">
        <label className="mt-1">現在価格</label>
        <small>{getTimeInJST(quote?.currentPriceAt)}更新</small>
      </div>
      <div className="input-group">
        <input type="text" className="form-control" value={quote?.currentPrice || "取得中..."} disabled />
        <button className="btn btn-outline-secondary manual-order-modal__quote-button" onClick={updateQuote}>
          更新
        </button>
      </div>
    </>}

    <form action="/user/manual_entry_orders" method="post" ref={formRef}>
      <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
      <input type="hidden" name="subscription_uid" value={subscription?.uid || ''} />
      <input type="hidden" name="instrument_ticker" value={instrument?.value || ''} />
      <input type="hidden" name="side" value={side || ''} />
      <input type="hidden" name="price" value={quote?.currentPrice || ''} />
      {quantityMethod === 'manual' && (
        <input type="hidden" name="quantity" value={quantity || ''} />
      )}

      <div className="row mt-3">
        <div className="col-6 d-grid pe-2">
          {renderSubmitButton("sell", "成行売り", "btn-danger")}
        </div>
        <div className="col-6 d-grid ps-2">
          {renderSubmitButton("buy", "成行買い", "btn-success")}
        </div>
      </div>
    </form>
  </>);
}

export default ManualEntryOrderForm;