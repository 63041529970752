import * as React from 'react';

type Props = {
  defaultSecondPassword: string,
  defaultIsCheckboxChecked: boolean,
};

type State = {
  secondPassword: string,
  isCheckboxChecked: boolean,
};

class TachibanaSecondPasswordField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      secondPassword: props.defaultSecondPassword,
      isCheckboxChecked: props.defaultIsCheckboxChecked,
    }
  }

  render () {
    const {
      isCheckboxChecked,
      secondPassword
    } = this.state;

    return <>
      <div className="form-check form-switch">
        <input
          type="checkbox"
          className="form-check-input"
          checked={isCheckboxChecked}
          onChange={() => this.setState({ isCheckboxChecked: !isCheckboxChecked })}
        />
        <label
          className="from-check-label"
          onClick={() => this.setState({ isCheckboxChecked: !isCheckboxChecked })}
        >
          ロ暗証番号とは別に、第二暗証番号を設定する
        </label>
      </div>
      {isCheckboxChecked && <div className="form-group">
        <input
          value={secondPassword}
          className="form-control form-control-lg"
          autoComplete="off"
          type="password"
          id="tachibana_account_second_password"
          required={true}
          onChange={(e) => this.setState({ secondPassword: e.target.value }) }
        />
        <p className="help-text">間違っている場合、発注・決済を行えませんのでご注意ください。保存時にはこの値は検証されません。</p>
      </div>}
      <input type="hidden" name="tachibana_account[second_password]" value={secondPassword} />
    </>;
  }
}

export default TachibanaSecondPasswordField;