import React, { useState } from 'react';
import UserTagCheckboxes, { UserTagOptions } from './UserTagCheckboxes';

type Props = {
  userTagOptions: UserTagOptions,
  defaultUserTags: string[],
  fieldName: string,
};

const UserTagField: React.FC<Props> = ({
  userTagOptions,
  defaultUserTags,
  fieldName,
}) => {
  const [userTags, setUserTags] = useState<string[]>(defaultUserTags);
  return(
    <>
      <div className="form-group row">
        <div className="col-md-8">
          <label>ユーザタグ</label>
          <br />
          <UserTagCheckboxes
            userTagOptions={userTagOptions}
            userTags={userTags}
            setUserTags={setUserTags}
          />
        </div>
      </div>

      <input type="hidden" name="user[user_tag_uids][]" value="" />
      {userTags.map((userTag) => (
        <input
          type="hidden"
          name={fieldName}
          value={userTag}
          key={userTag}
        />
      ))}
    </>
  );
};

export default UserTagField;