import React from 'react';

type UserTagOptions = {
  value: string,
  label: string,
}[];

type Props = {
  userTagOptions: UserTagOptions,
  userTags: string[],
  setUserTags: (userTags: string[]) => void,
};

const UserTagCheckboxes: React.FC<Props> = ({
  userTagOptions,
  userTags,
  setUserTags,
}) => {
  const toggleUserTag = (userTag: string) => {
    if (userTags.includes(userTag)) {
      setUserTags(userTags.filter((tag) => tag !== userTag));
    } else {
      setUserTags([...userTags, userTag]);
    }
  };

  return(
    <>
      {userTagOptions.map((option) => (
        <div className="form-check form-check-inline" key={option.value}>
          <input
            className="form-check-input"
            type="checkbox"
            id={`user_tag_${option.value}`}
            value={option.value}
            checked={userTags.includes(option.value)}
            onChange={() => toggleUserTag(option.value)}
          />
          <label className="form-check-label" htmlFor={`user_tag_${option.value}`}>{option.label}</label>
        </div>
      ))}
    </>
  )
};

export default UserTagCheckboxes;
export type { UserTagOptions };
